<template>
  <div id="app" class="h-100 custom-color  " :class="[skinClasses, { 'custom-fpt': isFPT }]">
    <component :is="layout">
      <router-view/>
    </component>

    <scroll-to-top v-if="enableScrollToTop"/>
  </div>
</template>

<script>
import {
  ref,
  onUnmounted
} from '@vue/composition-api'


import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

import signalrHub from '@core/signalr/signalrHub.js'

// This will be populated in `beforeCreate` hook
import {
  $themeColors,
  $themeBreakpoints,
  $themeConfig
} from '@themeConfig'
import {
  provideToast
} from 'vue-toastification/composition'
import {
  watch
} from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import {
  useWindowSize,
  useCssVar
} from '@vueuse/core'

import {
  isUserLoggedIn
} from '@/auth/utils'

import store from '@/store'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

    ScrollToTop,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const {
      isRTL
    } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')

  },
  setup() {
    const {
      skin,
      skinClasses
    } = useAppConfig()

    const {
      enableScrollToTop
    } = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 5000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const {
      width: windowWidth
    } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    const user = localStorage.userData != undefined ? JSON.parse(localStorage.userData) : {}
    const url = window.location.href;
    //const url = 'fpt.onboard.ind.br'
    const isFPT = ref(false)

    if (url.includes('fpt')) {
      isFPT.value = true
    }


    return {
      skinClasses,
      enableScrollToTop,
      user,
      isFPT
    }
  },
  mounted() {
    if (isUserLoggedIn()) signalrHub.start();
    var self = this;

    //Registra o signalR da telemetria
    signalrHub.connection.on("tlm", (content) => {
      console.log("App.vue", content);
      console.log("Tipo: ", typeof objeto);
      self.$root.$emit("tlm", content);
    });

    //Registra o signalR dos alertas
    signalrHub.connection.on("evt", (content) => {
      console.log("EVT: ", content);
      this.$root.$emit("evt", content);
    });

    if (localStorage.userData) {

      var userData = JSON.parse(localStorage.userData)

      this.$i18n.locale = userData.language
    }


    //headerNotifications()
  },

}
</script>

<style>

/*Primary BTN*/
.custom-color.custom-fpt .btn-primary {
  background-color: #e41e2d !important;
  border-color: #e41e2d !important;
}

.custom-color.custom-fpt .btn-primary:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(255, 0, 0, 0.5) !important;
}

.custom-color.custom-fpt .btn-primary:active {
  background-color: #a4161a !important;
  border-color: #e41e2d !important;
}

/*SideBar Selector*/
.custom-color.custom-fpt .main-menu.menu-light .navigation > li.active > a {
  background: linear-gradient(118deg, #e41e2d, rgba(228, 30, 45, 0.7)) !important;
  border-color: #e41e2d !important;
  box-shadow: 0 0 10px 1px rgba(240, 103, 103, 0.7);
}

.custom-color.custom-fpt .main-menu.menu-light .navigation > li ul .active {
  background: linear-gradient(118deg, #ff6f61, rgba(255, 112, 100, 0.7)) !important;
  color: whitesmoke !important;
  border-color: #e41e2d !important;
  box-shadow: 0 0 10px 1px rgba(240, 103, 103, 0.7);
}

.custom-color.custom-fpt .main-menu.menu-light .navigation > li.open:not(.menu-item-closing) > a, .custom-color.custom-fpt .main-menu.menu-light .navigation > li.sidebar-group-active > a {
  background: linear-gradient(118deg, #e41e2d, rgba(228, 30, 45, 0.7)) !important;
  border-color: #e41e2d !important;
  box-shadow: 0 0 10px 1px rgba(240, 103, 103, 0.7);
  color: whitesmoke;
}


/*
.custom-color.custom-fpt .nav.navbar-nav.flex-row a{
  color: red;
}

 */
.custom-color.custom-fpt .main-menu .collapse-toggle-icon {
  color: red !important;
}


</style>
