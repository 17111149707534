import Vue from 'vue'
import { BToast } from 'bootstrap-vue'
// axios
import axios from 'axios'

import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'

import route from '@/router/index'


//import app from '@/main'



const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  //baseURL: 'http://localhost:5083',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

const resolveVariant = (type) => {
  if (type == 'error') return 'danger'
  if (type == 'warning') return 'warning'
  if (type == 'success') return 'success'
  if (type == 'information') return 'info'

}

axiosIns.interceptors.response.use(
  function funcSuccess(response) {
    if (response.headers && response.headers.notifications && response.headers.notifications.length > 0) {

      let bootStrapToaster = new BToast();
      var notifications = JSON.parse(response.headers.notifications);


      notifications.map(function (node) {

        bootStrapToaster.$bvToast.toast(node.text, {
          title: `✉️ ${node.type.toUpperCase()}`,
          toaster: "b-toaster-bottom-right",
          solid: true,
          variant: resolveVariant(node.type),
          appendToast: false
        })


      });

    }
    return response
  },
  error => {
    // const { config, response: { status } } = error
    const { config, response } = error


    // if (status === 401) {
    if (response && response.status === 401) {


      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Redirect to login page
      route.push({ name: 'auth-login' })
        .then(() => {
          // notification to user via bootstrap toast

          let bootStrapToaster = new BToast();

          bootStrapToaster.$bvToast.toast(`Unauthorized Access`, {
            title: `✉️ ERROR`,
            toaster: "b-toaster-bottom-right",
            solid: true,
            variant: 'danger',
            appendToast: false
          })

        })




    }
    return Promise.reject(error)
  },
)

Vue.prototype.$http = axiosIns

export default axiosIns
