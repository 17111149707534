export default [{
        path: '/',
        name: 'home',
        component: () =>
            import ('@/views/main/Main.vue'),
        meta: {},
    },
    {
        path: '/map',
        name: 'map',
        component: () =>
            import ('@/views/main/Map.vue'),
        meta: {},
    },
    {
        path: '/apps/asset/preview/:id',
        name: 'apps-asset-preview',
        component: () =>
            import ('@/views/apps/asset/asset-preview/AssetPreview.vue'),
    },
    {
        path: '/customer/list',
        name: 'list-customer',
        component: () =>
            import ('@/views/main/customer/ListCustomer.vue'),
    },
    {
        path: '/customer/register/:id?',
        name: 'register-customer',
        component: () => import('@/views/main/customer/RegisterCustomer.vue'),
    },
    {
        path: '/dealer/list',
        name: 'list-dealer',
        component: () =>
            import ('@/views/main/dealer/ListDealer.vue'),
    },
    {
        path: '/dealer/register/:id?',
        name: 'register-dealer',
        component: () => import('@/views/main/dealer/RegisterDealer.vue'),
    },
    {
        path: '/user/list',
        name: 'list-user',
        component: () =>
            import ('@/views/main/user/UsersList.vue'),
    },
    {
        path: '/user/register/:id?',
        name: 'register-user',
        component: () => import('@/views/main/user/RegisterUser.vue'),
    },
    {
        path: '/device/list',
        name: 'list-device',
        component: () =>
            import ('@/views/main/device/device-list/ListDevice.vue'),
    },
    {
        path: '/device/register/:id?',
        name: 'register-device',
        component: () => import('@/views/main/device/device-register/RegisterDevice.vue'),
    },
    {
        path: '/device/install/:id',
        name: 'install-device',
        component: () => import('@/views/main/device/device-install/InstallDevice.vue'),
    },
    {
        path: '/device/uninstall/:id',
        name: 'uninstall-device',
        component: () => import('@/views/main/device/device-uninstall/UninstallDevice.vue'),
    },
    {
        path: '/asset/preview/:id',
        name: 'preview-asset',
        component: () => import('@/views/main/asset/asset-preview/AssetPreview.vue'),
    },
    {
        path: '/asset/register/:id?',
        name: 'register-asset',
        component: () => import('@/views/main/asset/asset-register/RegisterAsset.vue'),
    },
    {
        path: '/queries/telemetry/:id',
        name: 'queries-telemetry',
        component: () => import('@/views/main/queries/telemetry/GraphicBoardDiary.vue'),
    },
    {
        path: '/queries/telemetry/GraphicTelemetryMonth/:id',
        name: 'queries-month',
        component: () => import('@/views/main/queries/telemetry/GraphicTelemetryMonth.vue'),
    },
    {
        path: '/management/fuelling/register/:id?',
        name: 'register-fuelling',
        component: () => import('@/views/main/management/fuelling/RegisterFuelling.vue'),
    },
    {
        path: '/management/fuelling/list',
        name: 'list-fuelling',
        component: () => import('@/views/main/management/fuelling/FuellingList.vue'),
    },
    {
        path: '/management/fuellingbulk',
        name: 'fuelling-bulk',
        component: () => import('@/views/main/management/fuelling/FuellingImport.vue'),
    },
    {
        path: '/device/registerbulk/list',
        name: 'device-bulk',
        component: () => import('@/views/main/device/device-register/RegisterDeviceBulk.vue'),
    },
    {
        path: '/fence/registerfence/:id?',
        name: 'fence-register',
        component: () => import('@/views/main/fence/RegisterFence.vue'),
    },
    {
        path: '/point/registerpoint/:id?',
        name: 'point-register',
        component: () => import('@/views/main/point/RegisterPoint.vue'),
    },

    
    {
        path: '/driver/list',
        name: 'list-driver',
        component: () =>
            import ('@/views/main/driver/driver-list/DriversList.vue'),
    },
    {
        path: '/driver/register/:id?',
        name: 'register-driver',
        component: () => import('@/views/main/driver/driver-register/RegisterDriver.vue'),
    },

    {
        path: '/alarms/settings/:id',
        name: 'settings-alarms',
        component: () => import('@/views/main/alarms/AlarmSettings.vue'),
    },

    {
        path: '/alarms/customer',
        name: 'customer-alarms',
        component: () => import('@/views/main/alarms/AlarmSettingsCustomer.vue'),
    },

    {
        path: '/management/preventivemaintenanceplan/register/:id?',
        name: 'register-maintenance-plan',
        component: () => import('@/views/main/management/maintenancePlan/RegisterMaintenancePlan.vue'),
    },

    {
        path: '/management/preventivemaintenanceplan/list',
        name: 'list-maintenance-plan',
        component: () =>
            import ('@/views/main/management/maintenancePlan/MaintenancePlanList.vue'),
    },

    {
        path: '/management/maintenance/:id',
        name: 'maintenance',
        component: () => import('@/views/main/management/maintenance/Maintenance.vue'),
    },

    {
        path: '/management/maintenanceassets/:id',
        name: 'maintenance-assets',
        component: () => import('@/views/main/management/maintenance/MaintenanceAssets.vue'),
    },

    {
        path: '/management/group/list',
        name: 'list-group',
        component: () =>
            import ('@/views/main/management/group/GroupList.vue'),
    },

    {
        path: '/management/group/register/:id?',
        name: 'register-group',
        component: () => import('@/views/main/management/group/RegisterGroup.vue'),
    },
    
    {
        path: '/queries/workstatus/:id',
        name: 'queries-weekly-work-status',
        component: () => import('@/views/main/queries/workStatus/weeklyWorkStatus.vue'),
    },

    {
        path: '/queries/geodata/:id',
        name: 'geo-data',
        component: () => import('@/views/main/queries/geoData/geoData.vue'),
    },
    {
        path: '/queries/telemetry/TelemetryTableHistory/:id',
        name: 'telemetry-table',
        component: () => import('@/views/main/queries/telemetry/TelemetryTableHistory.vue'),
    },

    {
        path: '/ControlFleet/dashboards',
        name: 'assets-dashboards',
        component: () => import('@/views/main/dashboards/dashboards.vue'),
    },

    {
        path: '/reports/usage',
        name: 'report-usage',
        component: () => import('@/views/main/reports/UsageReport.vue')
    },

]