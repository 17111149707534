//import Vue from 'vue'

let signalR = require('@aspnet/signalr')

export default {

    connection: null,

    connect() {
                
        this.connection.start().then(() => {

            //this.connection.on("PlayPauseChange", action => {
            //    Vue.prototype.$eventHub.$emit('play-pause-change', action)
            //});

        }, () => {
            setTimeout(() => {
                this.connect()
            }, 5000);
        });

        this.connection.onclose(() => {
            this.connect();
        });

    },

    start() {
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl('/hub', {
                accessTokenFactory: () => localStorage.getItem('accessToken')
            })
            .build();

        setTimeout(() => {
            this.connect();
        }, 1000);
    }

}